import { capitalizeFirst, rgbaPattern } from "../../../Utils/Utils";
import { getAssetUrl } from "../../../Utils/SalesUtils";

const Category = ({
    mainLvlCategory,
    data,
    t,
    availableCategory,
    idsSubrows,
    val = 1,
    parentId = null,
    openModalModifyCategoryState,
}) => {
    const subrowData = [];

    if (data.children) {
        data.children.forEach((item) => {
            subrowData.push(
                Category({
                    mainLvlCategory,
                    data: item,
                    t,
                    availableCategory,
                    idsSubrows,
                    val: val + 1,
                    openModalModifyCategoryState,
                })
            );
        });
    }

    // console.log(data);

    const hasChildren = data.children && data.children.length > 0;
    const canHaveChildren = data.customFields && data.customFields.can_have_children ? true : false;
    const parentEnabled = data && (data.mainParentEnabled === false || data.parentEnabled === false);
    const facetValueIds = findAllFacetValueIds(data);
    const facetValue = facetValueIds;
    const baseAdjust = `${
        mainLvlCategory === 0
            ? val === 2
                ? "ml-10 opacity-0"
                : val === 3
                ? "ml-18 opacity-0"
                : val === 4
                ? "ml-28 opacity-0"
                : ""
            : val === 1
            ? " hidden"
            : val === 2
            ? "  opacity-0"
            : val === 3
            ? " opacity-0"
            : val === 4
            ? " opacity-0"
            : ""
    }`;
    const isColor = rgbaPattern.test(data.customFields.image_url) || data.customFields.image_url === "" ? true : false;

    const createInfo = {
        image: {
            cellContent: "image",
            disabled: parentEnabled || availableCategory === false ? true : false,
            isFromUrl: true,
            urlPath: !isColor ? getAssetUrl(data.customFields.image_url) : data.customFields.image_url,
            path: "icons/",
            type: "svg",
            name: "image",
            buttonOpenSubrow: hasChildren ? data.name : null,
            linkText: !hasChildren ? data.name : null,
            noImgText: true,
            specialWidth: `w-4/12`,
            notImgFontSize: "0.65rem",
            adjustNotImageIcon: "icon icon-no-image text-gray-400 text-xl",
            adjust: `w-18 h-12 object-cover rounded mr-4 ${
                val > 1 || parentEnabled || availableCategory === false ? " h-12  opacity-0" : "h-12 opacity-100"
            } ${baseAdjust}`,
            adjustNotImage: `flex items-center justify-center bg-gray-200 w-18  rounded mr-4 ${
                val > 1 || parentEnabled || availableCategory === false ? " h-12 opacity-0" : "h-12 opacity-70"
            } ${baseAdjust}`,
            adjustColorBg: `flex items-center justify-center w-18  rounded mr-4 ${
                val > 1 || parentEnabled || availableCategory === false ? " h-12 aa opacity-0" : "h-12 opacity-70"
            }  ${baseAdjust}`,
            subrowAdjust: hasChildren
                ? `absolute bottom-0 rounded-tr rounded-bl bg-gray-700 bg-opacity-70 h-8 w-8 text-white flex items-center justify-center ${baseAdjust}`
                : null,
            subrowOpenIcon: "icon icon-category text-lg",
            subrowCloseIcon: "icon icon-folder-outline",
            adjustText: hasChildren ? `flex items-center text-zafiro-600 font-bold cursor-pointer  ` : "",
            value: data.name ? data.name : null,
            rowId: data.id,
        },
        text_products: {
            cellContent: "text",
            value: canHaveChildren
                ? idsSubrows.includes(Number(data.id))
                    ? ""
                    : data.totalItems + " " + t("products")
                : data.totalItems + " " + t("products"),
            specialWidth: `w-4/12 ${canHaveChildren ? "font-bold" : ""}`,
            adjust: parentEnabled || availableCategory === false ? "text-gray-600" : "",
            wrap: "truncate",
        },
        text_availability: {
            cellContent: "text",
            value: capitalizeFirst(
                data && data.customFields && data.customFields.enabled ? t("available") : t("unavailable")
            ),
            specialWidth: `w-4/12 text-left ${
                parentEnabled || availableCategory === false
                    ? "text-gray-600"
                    : data && data.customFields && data.customFields.enabled
                    ? "text-green-100"
                    : "text-red-100"
            } `,
            wrap: "truncate",
        },
    };

    return {
        dataType: "categorySales",
        id: data.id || null,
        openModalModifyCategoryState: () => {
            openModalModifyCategoryState({
                id: data?.id,
                name: data?.name,
                available: data?.customFields?.enabled,
            });
        },
        idShop: data.idShop ? data.idShop : null,
        actions: [
            {
                type: "categorySales",
                value: data.id || null,
            },
        ],
        t: t,
        facetValueIds: facetValueIds,
        executeSectionQuery: true,
        actionComponentStyle: { left: "-160px" },
        subrow: hasChildren,
        subrowData: subrowData,
        rowAddon:
            val > 1 && mainLvlCategory === 0
                ? {
                      widthBottom: val === 2 ? "w-32" : val === 3 ? "w-40" : "",
                      widthTop: val === 2 ? "w-32" : val === 3 ? "w-40" : "",
                  }
                : false,
        parentId: parentId,
        name: data.name,
        levelCategory: val,
        mainLevelCategory: mainLvlCategory,
        parentAvailable: data && data.mainParentEnabled && data && data.parentEnabled ? true : false,
        availableActions:
            data && data.mainParentEnabled && data && data.parentEnabled && availableCategory ? true : false,
        childrenCount: data.childrenCount,
        available: data.customFields.enabled ? true : false,
        disableCheckbox: val > 1 ? true : false,
        hideCheckbox: val > 1 ? true : false,
        info: [createInfo.image, createInfo.text_products, createInfo.text_availability],
    };
};

export default Category;

const findAllFacetValueIds = (data) => {
    let ids = [];

    const findAndParseFacetValueIds = (data) => {
        if (data.filters && Array.isArray(data.filters) && data.filters.length > 0) {
            const facetValueIdsString = data.filters[0].args.find((arg) => arg.name === "facetValueIds")?.value;
            if (facetValueIdsString) {
                try {
                    return JSON.parse(facetValueIdsString);
                } catch (error) {
                    console.error("Error parsing facetValueIds:", error);
                    return [];
                }
            }
        }
        return [];
    };

    ids = ids.concat(findAndParseFacetValueIds(data));

    for (let key in data) {
        if (data.hasOwnProperty(key) && typeof data[key] === "object" && data[key] !== null) {
            ids = ids.concat(findAllFacetValueIds(data[key]));
        }
    }

    return ids;
};
