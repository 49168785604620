import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

import { Component, ListComponents } from "sections/playground";

import Button from "components/Button";
import FileHandler from "components/FileHandler";
import Switch from "components/Switch";
import Checkbox from "components/Inputs/Checkbox";
import CurrencyInput from "components/Inputs/CurrencyInput";
import Radiobuttons, { Radiobutton } from "components/Inputs/Radiobuttons";
import Toggle from "components/Inputs/Toggle";
import { useAlert } from "components/Modal";
import TextInput from "components/TextInput";
import ColorInput from "components/ColorInput";
import Dropdown from "components/Dropdown";
import PriceWithTax from "components/Inputs/PriceWithTax";
import Translations from "components/Translations";
import SelectorItem from "components/SelectorItem";
import ImageColorInput from "components/ImageColorInput";

const Inputs = () => {
    const inputFileRef = useRef(null);
    const displayFilesRef = useRef(null);

    const { open: alert } = useAlert();

    const [selectorValue, setSelectorValue] = useState(3);

    return (
        <ListComponents>
            <ColorInput id="color-1" value="#FF0000" disabled={true} />
            <ColorInput id="color-2" placeholder="Enter color" required={true} />
            <ColorInput id="color-3" value="#36f" />
            <ColorInput id="color-4" value="rgba(255,255,0,.7)" />
            <ColorInput
                id="color-5"
                value="pink"
                onChange={(color) => {
                    console.log(`Color changed to ${color}`);
                }}
            />

            <ImageColorInput id="image-color-1" />

            <Component title="SelectorItem">
                <div>
                    <div className="font-bold mb-2 text-lg">Editable example</div>
                    <SelectorItem
                        type="satisfaction"
                        id="pending-item-test-1"
                        value={selectorValue}
                        onChange={setSelectorValue}
                    />
                    <p className="pt-2">Selected value: {selectorValue}</p>
                    <Button design="classic-link" id="reset-test" onClick={() => setSelectorValue(3)}>
                        Reset value
                    </Button>
                </div>
                <div>
                    <div className="font-bold mb-2 text-lg">Read only example</div>
                    <SelectorItem type="satisfaction" id="pending-item-test-2" value={3} readOnly={true} />
                </div>
                <div>
                    <div className="font-bold mb-2 text-lg">Disabled example</div>
                    <SelectorItem type="satisfaction" id="pending-item-test-3" value={2} disabled={true} />
                </div>
            </Component>

            <Translations onChange={(values) => alert(JSON.stringify(values, null, 2))} />
            <Translations
                id="test-translations"
                languages={["fr", "es", "en", "it"]}
                translations={[{ languageCode: "fr", name: "FR test" }]}
                defaultLang="en"
                onChange={(translations) => {
                    console.log("Translations changed", translations);
                }}
                className="border border-dashed border-zafiro-200 p-5 rounded-lg"
                maxLength={30}
                maxHeight="10rem"
                multiline={2}
                template={[
                    { languageCode: "fr", name: "France" },
                    { languageCode: "es", name: "Spain" },
                    { languageCode: "en", name: "England" },
                    { languageCode: "it", name: "Italy" },
                ]}
            />
            <Translations
                id="test-translations-yellow"
                design="cols"
                languages={["eu", "es", "ca"]}
                defaultLang="es"
                placeholder="Enter translation"
                onChange={(translations) => {
                    console.log("Translations changed", translations);
                }}
                className="border bg-yellow-200 border-dashed border-yellow-400 p-5 rounded-lg"
            />

            <PriceWithTax
                id="test-price"
                price={1400}
                taxID={2}
                currencyCode="EUR"
                taxes={[
                    { id: 1, value: 0, name: "None" },
                    { id: 2, value: 10, name: "IVA" },
                    { id: 3, value: 15, name: "VAT" },
                    { id: 4, value: 20, name: "GST", default: true },
                ]}
                onChange={(data) => {
                    console.log("Price with tax changed", data);
                }}
            />

            <TextInput value="Lorem ipsum" disabled={true} />
            <TextInput placeholder="Enter your name" required={true} />
            <TextInput type="email" placeholder="Enter your email" required={true} />
            <TextInput type="password" placeholder="Enter password" />

            <CurrencyInput required={true} value={100} />
            <CurrencyInput currencyCode="EUR" minValue={5} maxValue={50} />

            <Toggle
                on={<div className="cursor-pointer p-5 text-xl text-blue-200">Custom ON</div>}
                off={<div className="cursor-pointer p-5 text-xl text-pink-500">Custom OFF</div>}
                label="Custom toggle"
            />
            <Toggle design="radio" label="Radio toggle" />
            <Toggle
                on={<div className="cursor-pointer text-4xl text-green-100 icon-Face-happy"></div>}
                off={<div className="cursor-pointer text-4xl text-red-100 icon-Face-angry"></div>}
            />

            <Switch disabled={true} checked={false} />
            <Switch label="Test" />
            <Switch
                label={"Switch label"}
                disabled={true}
                checked={true}
                onChange={({ checked }) => alert(`Switch is ${checked ? "on" : "off"}`)}
            />

            <Checkbox disabled={true} checked={true} />
            <Checkbox disabled={true} checked={false} label="Disabled check" />
            <Checkbox label="Example" />
            <Checkbox
                label="Lorem ipsum sit amet"
                checked={true}
                onChange={({ checked }) => alert(`Checkbox is ${checked ? "checked" : "unchecked"}`)}
            />
            <Component title="Radiobuttons">
                <Radiobuttons
                    checked="A"
                    options={[
                        { label: "Test A", value: "A" },
                        { label: "Test B", value: "B", disabled: true },
                        { label: "Test C", value: "C" },
                    ]}
                    className={"pr-5"}
                />
                <div className="flex flex-row gap-4">
                    <Radiobuttons options={["One", "Two", "Three"]} className={"text-green-500 font-bold"} />
                </div>
                <div className="flex flex-row gap-4">
                    <Radiobutton label="Enabled" checked={false} />
                    <Radiobutton label="Disabled ON" checked={true} disabled={true} />
                    <Radiobutton label="Disabled OFF" checked={false} disabled={true} />
                </div>
            </Component>

            <Component title="FileHandler">
                <FileHandler
                    ref={inputFileRef}
                    id="select-file"
                    options={{
                        multiple: true,
                        contentTypes: ["image"],
                    }}
                    onSuccess={(v) => {
                        console.log("FileHandler success", v);
                        if (displayFilesRef.current) {
                            displayFilesRef.current.setFiles(v);
                        }
                    }}
                    onError={(error) => {
                        alert(error);
                    }}
                >
                    <Button
                        id="select-file-btn"
                        design="dark-blue"
                        onClick={() => {
                            if (inputFileRef.current) {
                                inputFileRef.current.click();
                            }
                        }}
                    >
                        <i className="icon icon-image mr-2"></i> Choose images
                    </Button>
                </FileHandler>
                <SelectedFiles ref={displayFilesRef} />

                <Dropdown />
                <Dropdown handler="Handler" body="Body here" />
                <Dropdown handler={<div className="p-5 text-pink-600 bg-pink-200">Handler</div>}>
                    <div className="p-5 bg-yellow-200">
                        Body <strong>here</strong>
                    </div>
                </Dropdown>
            </Component>
        </ListComponents>
    );
};

const SelectedFiles = forwardRef(({ className }, ref) => {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [previewImages, setPreviewImages] = useState({});
    const [showPreview, setShowPreview] = useState(null);

    useImperativeHandle(ref, () => ({
        setFiles: (files) => {
            setSelectedFiles(files);
        },
    }));

    useEffect(() => {
        if (selectedFiles) {
            selectedFiles.forEach((file, index) => {
                if (file) {
                    const key = `file_${index}`;
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setPreviewImages((prev) => ({ ...prev, [key]: e.target.result }));
                    };
                    reader.readAsDataURL(file.file);
                }
            });
        }
    }, [selectedFiles]);

    if (!selectedFiles?.length) {
        return null;
    }

    return (
        <>
            <div className={`grid grid-cols-2 gap-2 ${className || ""}`}>
                {selectedFiles
                    .filter((file) => file)
                    .map((file, index) => {
                        const name = file.file?.name;
                        const shortName = name.length > 16 ? `${name.substring(0, 16)}...` : name;
                        const preview = previewImages?.[`file_${index}`];
                        return (
                            <div key={name} className="border p-5">
                                <span
                                    className="font-bold whitespace-no-wrap"
                                    data-tip={name}
                                    data-for="default-tooltip"
                                >
                                    {shortName}
                                </span>
                                <br />
                                <span className="text-sm">Type: {file.file?.type}</span>
                                <br />
                                <span className="text-sm">Size: {Math.round(file.file?.size / 1024)} KB</span>
                                {preview ? (
                                    <img
                                        src={preview}
                                        alt={name}
                                        className="p-2 mt-2 border bg-white w-full"
                                        onClick={() => {
                                            setShowPreview(preview);
                                        }}
                                    />
                                ) : null}
                            </div>
                        );
                    })}
            </div>
            {showPreview && <FullScreenImage src={showPreview} onClick={() => setShowPreview(null)} />}
        </>
    );
});

const FullScreenImage = ({ src, onClick }) => {
    return (
        <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-200"
            onClick={onClick}
        >
            <img src={src} alt="Preview" className="max-w-full max-h-full" />
        </div>
    );
};

export default Inputs;
